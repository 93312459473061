@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import url('https://fonts.googleapis.com/css?family=Montserrat');

body {
/*   color: #244752; */
  color: #ffffff;
  font-family: 'Montserrat', sans-serif;
}

.my-container {
  width: 100%;
}

.c-container {
  background-color: #16a75c;
  border-radius: .5em;
  overflow: hidden;
  padding-left: 0px;
  padding-right: 0px;
  color: white;
  margin: 10px 10px 10px 10px;
  
}

.header {
  text-align: center;
  background-color: #106eae;
  color: white;
  padding: 0.5em 1em 0.5em 1em;
  font-size: 1em;
}

h1 {
  margin-top: 0.3em;
  margin-bottom: 0.3em;
  font-weight: bold;
  font-family: 'Montserrat', cursive;
  font-size: 1.5em;
  line-height: 1em;
}

p {
  margin: 0;
}

form {
  padding: 1em;
  display: flex;
  flex-direction: column;
}


button{
  padding: 0.4em 1.3em 0.4em 1.3em;
  font-size: 1.3em;
  color: white;
  background-color: #087f23;
  border-color: #087f23;
  border-radius: .2em;
  margin: 0 auto;
  display: block;
}

label, button, p.label {
  font-family: 'Montserrat', cursive;
  font-weight: bold;
}

input, textarea, select {
  font-size: 1em;
  padding: 10px 5px 10px;
  border-radius: 8px;
}

input[type="text"], input[type="email"] {
  flex: 0 1 250px;
}

input[type="number"] {
  flex: 0 0 6em;
}

label.wide {
  flex: 1 1 140px;
}


input[type="checkbox"] + label, div.radio-control label {
  font-family: 'Montserrat', sans-serif;
}

input[type="checkbox"] {
  margin-right: 10px;
}

.row {
  display: flex;
  align-items: center;
  padding: 6px;
}

.small-row {
  display: flex;
  align-items: center;
  padding: 3px;
  text-align: center;
}

div.radio-control {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  font-size: 14px;
}

.m-t-20 {
  margin-top: 20px;
}

.justify-sb {
  justify-content: space-between;
}

textarea {
  width: 100%;
}